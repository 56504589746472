@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    font-size: 17px;
    font-family: 'Overpass', sans-serif;
}

a {
    text-decoration: none;
    color: $dark
}

p {
    font-size: clamp(.8rem, 2.5vw, 1rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Days One', sans-serif;
    font-weight: 800;
}

body {

    &.preloader-show {
        overflow: hidden;

        .preloader {
            background-color: $orange-3;
            display: block;
            height: 100%;
            width: 100%;

            .preloader-img,
            .preloader-text {
                position: absolute;
                width: 200px;
                left: 50%;
            }

            .preloader-img {
                transform: translate(-50%, -100%);
            }

            .preloader-text {
                top: 50%;
                opacity: 0;
                transform: translate(-50%, -50%);
            }
        }

        main {
            display: none;
        }
    }

    &.menu-show {
        .navigation {
            z-index: 1000;
        }

        .navigation__menu {
            top: 0;
            opacity: 1;
        }

        .socials {
            transition: .5s all;
            background: none;
            opacity: .6;

            &:hover {
                opacity: 1;
            }
        }
    }

    main {
        overflow: hidden;
    }
}

.logo {
    position: fixed;
    left: 1rem;
    top: 1rem;

    img {
        width: 70px;
    }
}

.main__bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background-image: url(../images/bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-position: 50% 50%;
}

.float__bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(20px);
    height: 110vh;
    transition-property: ease;
    opacity: .3;

    @include breakpoint-up(md) {
        width: 110vw;
        height: unset;
    }
}

.navigation {
    position: fixed;
    display: flex;
    align-items: center;
    padding: .2rem .675rem;
    top: 50%;
    opacity: 0;
    transition: .5s all;
    z-index: 9999;
    animation: .5s fromLeft forwards;
    animation-delay: 1s;
    box-shadow: 2px 5px 20px #0000003b;
    background: $dark;

    @include breakpoint-up(md) {
        padding: .275rem .875rem;
    }

    &:hover {
        opacity: 1;
    }


    svg {
        margin: auto;
        width: 8px;
        margin-right: .3rem;

        path {
            fill: white;
        }
    }

    .menu-btn {
        --box-shadow-color: rgba(255, 101, 47, 0.2);
        width: 20px;
        height: 50px;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }

    .menu-btn__burger,
    .menu-btn__burger::before,
    .menu-btn__burger::after {
        width: 1rem;
        height: 2px;
        border-radius: 5px;
        background-color: #fff;
        transition: all 0.5s ease-in-out;

        @include breakpoint-up(md) {
            width: 1.2rem;
        }
    }

    .menu-btn__burger::before,
    .menu-btn__burger::after {
        content: "";
        position: absolute;
    }

    .menu-btn__burger::before {
        transform: translateY(-10px);
    }

    .menu-btn__burger::after {
        transform: translateY(10px);
    }

    /* ANIMATION */
    .menu-btn.open .menu-btn__burger {
        transform: translateX(-50px);
        background-color: transparent;
        box-shadow: none;
    }

    .menu-btn.open .menu-btn__burger::before {
        transform: rotate(45deg) translate(35px, -35px);
    }

    .menu-btn.open .menu-btn__burger::after {
        transform: rotate(-45deg) translate(35px, 35px);
    }

}

.socials {
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: column;
    opacity: 0;
    right: 0;
    top: 50%;
    padding: .5rem 0;
    margin: 0;
    transform: translateY(-50%);
    z-index: 9999;
    animation: .5s fromRight forwards;
    animation-delay: 1s;
    list-style: none;
    background-color: #F2F2F2;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.061);

    li {
        padding: .5rem;
        display: flex;
        width: fit-content;

        a {
            text-decoration: none;
            opacity: .8;
            transition: .3s all;

            &:hover {
                opacity: 1;
            }
        }

        svg {
            width: 45px;
            height: 45px;

            @include breakpoint-up(md) {
                width: 50px;
                height: 50px;
            }
        }
    }
}

nav.navigation__menu {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark;
    opacity: .5;
    width: 100%;
    height: 100vh;
    top: -120vh;
    color: white;
    z-index: 999;
    transition: .5s cubic-bezier(0.39, 0.575, 0.565, 1);

    .navigation__wrapper {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .navigation__item {
        font-size: 2rem;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1px;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        @include breakpoint-up(md) {
            font-size: 2.5rem;
        }

        @include breakpoint-up(lg) {
            font-size: 3rem;
        }

        a {
            display: inline-block;
            color: rgb(198, 198, 198);
            opacity: 0;
            transition: .3s all;
            text-decoration: none;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                display: block;
                left: 50%;
                bottom: 15px;
                transform: translateX(-50%);
                width: 110%;
                background-color: $orange-4;
                z-index: -1;
            }

            &.active {
                font-weight: 900;
                color: white;
                opacity: 0;

                &:before {
                    animation: .2s item cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
                }

            }

            &:hover {
                transform: translateX(1rem) !important;
                opacity: 1 !important;
            }
        }
    }
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.greeting {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    z-index: 2;

    .quarter {
        position: relative;
        animation: 1s quarter forwards;
        background-color: $orange-5;

    }

    .greeting_logo {
        position: absolute;
        width: 60%;
        max-width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-47.5%, -50%);
        // opacity: 0;
        // animation: .5s fromTop forwards;
        animation-delay: .5s;
    }
}

.scroll {
    position: absolute;
    border: none;
    outline: none;
    box-shadow: none;
    opacity: 0;
    padding: .3rem 0;
    background: none;
    font-size: 1rem;
    right: -.5rem;
    bottom: 12%;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: .7rem;
    animation: .5s scrollButton forwards;
    animation-delay: .5s;
    transform: rotate(-90deg);

    span {
        position: absolute;
        z-index: 2;
        width: 30px;
        height: 1px;
        background: white;
        bottom: 50%;
        left: -70%;
        border-radius: 5px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: .4rem;
            height: 1px;
            background-color: #fff;
            left: -1px;
            z-index: 1;
        }

        &:after {
            transform: rotate(45deg);
            top: 2.1px;
        }

        &:before {
            transform: rotate(-45deg);
            bottom: 2.3px;
        }
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;

        span {
            transition: .2s all cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 50px;
            left: -100%;
        }
    }

}

.image__section {
    position: relative;
    box-sizing: border-box;
    padding: 5rem 0;
    text-align: center;
    // width: calc(100% - 3.5rem);
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    z-index: 1;

    // height: ;
    @include breakpoint-up(md) {
        padding: 0;
        height: 100vh;
    }


    img {
        &:not(.flame) {
            width: 75%;

            @include breakpoint-up(md) {
                width: 35%;
            }
        }
    }
}

.heading {
    h1 {
        display: inline-block;
        position: relative;
        color: white;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            width: 110%;
            height: 40%;
            left: 50%;
            bottom: .1rem;
            transform: translateX(-50%);
            background-color: $orange-4;
            z-index: -1;
        }
    }
}

.v_center {
    align-items: center;
}

.video__wrapper {
    position: relative;
    width: 80%;
    z-index: 3;

    video {
        box-shadow: 0 1rem 3rem #0000003b;
    }

    .controls {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 10rem;
        left: 50%;
        bottom: -1.25rem;
        transform: translateX(-50%);
        background-color: $light;
        border-radius: 5rem;
        box-shadow: 0 1rem 3rem #0000003b;

        button {
            outline: none;
            background: $dark;
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s all;

            svg {
                fill: $light;
            }
        }

    }
}

.float {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.float__reverse {
    animation-name: floatingReverse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.flame {
    position: absolute;

    &.top {
        position: fixed;
        top: -50%;
        transition: .05s all;
        z-index: -1;

        @include breakpoint-up(md) {
            width: 100%;
            left: 0;
            transform: translateX(0);
        }
    }

    &.center {
        position: fixed;
        transition: .05s all;
        bottom: 100%;

        @include breakpoint-up(md) {
            width: 100%;
            left: 0;
            transform: translateX(0);
        }
    }

    &.bottom {
        left: 50%;
        transform: translateX(-35%);
        bottom: 61px;
        width: 200%;

        @include breakpoint-up(md) {
            width: 100%;
            left: 0;
            bottom: 70px;
            transform: translateX(0);
        }
    }
}

.list {
    li {
        font-size: .8rem;

        @include breakpoint-up(md) {
            font-size: 1rem;
        }
    }
}

.bc_container {
    margin: 5rem auto;
    width: 90%;
    box-sizing: border-box;
}

.h_extra {
    height: 120vh !important;
}

.card {
    position: relative;
    padding: 4rem 5%;
    min-height: 80vh;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.061);
    opacity: 0;
}

footer {
    padding: .5rem 5%;
    box-sizing: border-box;
    background: #ee8815;
    position: relative;
    box-shadow: 0 0 2rem #0000000f;
    color: white;

    p {
        text-align: center;
    }
}

.photos {
    /* Prevent vertical gaps */
    line-height: 0;
    min-height: 70vh;
    margin-bottom: 2rem;
    transition: .3s all;

    -webkit-column-count: 5;
    -webkit-column-gap: 0px;
    -moz-column-count: 5;
    -moz-column-gap: 0px;
    column-count: 5;
    column-gap: 0px;
}

.photos img {
    /* Just in case there are inline attributes */
    width: 100%;
    height: auto;
    transition: .3s all;
}

@media (max-width: 1200px) {
    .photos {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
    }
}

@media (max-width: 1000px) {
    .photos {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 800px) {
    .photos {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

.pagination {
    display: flex;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.center {
    justify-content: center;
}

.t_center {
    text-align: center;
}

.button.dark,
.button.load-more {
    padding: .5rem 1rem;
    background: #403e42;
    color: white;
    vertical-align: middle;
    display: flex;
    align-items: center;
    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
    outline: 4px solid #403e42;
    border: 0;
    cursor: pointer;

    &:not(:disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):focus {
        background: #1e1e1e;
        outline-offset: 2px;
        transform: translateY(-3px);
        box-shadow: 0px 20px 20px 0px #0000003b;
    }

    &:disabled {
        cursor: not-allowed;
        background: #585858;
        outline-color: #585858;
    }
}

.blur {
    filter: blur(5px);
}

.logo-complete {
    margin: 0;
    position: absolute;
    top: 1rem;
    left: 5%;
}

.page-title {
    font-size: 200%;
    color: #f07c0000;
    text-transform: uppercase;
    -webkit-text-stroke: 2px #f07c0029;
    letter-spacing: 5px;
    position: absolute;
    top: -1.5rem;
    z-index: 9;
    right: 5%;
    margin: 0;

    @include breakpoint-up(sm) {
        font-size: 300%;
        top: -2rem;
        right: 8%;
        letter-spacing: 8px;
    }

    @include breakpoint-up(lg) {
        font-size: 400%;
        top: -3rem;
        right: 8%;
        letter-spacing: 10px;
    }

    &.sm {
        font-size: 150%;
        top: -1rem;
        margin: 0;

        @include breakpoint-up(sm) {
            font-size: 250%;
            top: -1.5rem;
            right: 8%;
            letter-spacing: 8px;
        }

        @include breakpoint-up(lg) {
            font-size: 350%;
            top: -2.5rem;
            right: 8%;
            letter-spacing: 10px;
        }
    }
}

.spotlight {
    position: relative;
    overflow: hidden;

    img {
        transition: .5s cubic-bezier(0.445, 0.05, 0.55, 0.95);

        &:hover {
            filter: grayscale(75%);
            transform: scale(1.03);
        }
    }
}

.text-center {
    text-align: center;
}

.hu {
    height: unset !important;
    min-height: unset !important;
}

.heading-text {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 800;
    color: #1e1e1e;
    text-transform: capitalize;
    margin-top: 0;
}

.company-color {
    color: #ee8815;
}

.body-text {
    color: #2f2f2f;
}

.dark-side {
    color: rgba(255, 255, 255, 0.721);
    font-size: clamp(.8rem, 2.5vw, 1rem);
    box-shadow: none;

    &:after {
        content: "";
        background-color: #1b1b1b;
        position: absolute;
        bottom: -20px;
        top: -20px;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        z-index: -2;
    }

    .heading-text {
        text-decoration: none;
        -webkit-text-decoration-style: none;
        text-decoration-style: none;
        color: transparent;
        text-transform: uppercase;
        -webkit-text-stroke: 1px #f07c00;
        font-size: 3rem;
        letter-spacing: 5px;
        position: absolute;
        top: -1.75rem;
        margin: 0;
        font-family: unset;
    }

    ol {
        padding-left: .75rem;

        li {
            margin-bottom: .5rem;
        }
    }
}

.dark-side__inner {
    background: #252525;
    padding: 1.5rem 5% .5rem;
    height: 100%;
    position: relative;
}

.date {
    text-align: right;
    font-size: .7rem;
    color: #585858;
}

.label {
    margin: 0;
    padding: .3rem .5rem;
    background: #585858;
    font-size: .7rem;
    color: white;
    display: inline-block;
    border-radius: .3rem;
}

.bc_chat {
    position: relative;
    padding: 1rem 2rem;
    color: #252525;
    background: #f8f8f8;
    border-radius: .5rem;
    transition: .3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &__header {
        p {
            margin: .5rem 0;
            color: rgb(0 0 0 / 50%);

            &:not(:last-child) {
                margin: .5rem 0 0;
            }
        }
    }

    &__body {
        color: #1e1e1e;

        .reply {
            padding: 1rem;
            margin-left: .5rem;
            background: white;
            border-radius: .3rem;
            border-left: 2px solid #f4ad04;
        }
    }
}

.input {
    margin-bottom: 1rem;

    label {
        display: inline-block;
        margin-bottom: .5rem;
        font-size: .875rem;
    }

    input,
    textarea {
        display: block;
        resize: none;
        width: 100%;
        padding: .75rem 1.25rem;
        border-radius: .5rem;
        outline: none;
        border: none;
        background: #F6F7F9;
        color: #748194;
    }

    textarea {
        height: 100px;
    }

    &.bg-white {

        input,
        textarea {
            background: white;
        }
    }

    &.bordered {

        input,
        textarea {
            border: 2px solid #F6F7F9;
        }
    }

    &.sm {
        margin-bottom: .7rem;


        label {
            margin-bottom: .25rem;
        }

        label,
        input,
        textarea {
            font-size: .8rem;
        }

        input,
        textarea {
            padding: .5rem .85rem;
        }
    }

}

.btn {
    border-radius: 24px;
    box-shadow: $box-shadow;
    color: $button-text-color;
    padding: .5rem 1rem;
    border: none;
    opacity: .8;
    transition: .5s all;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: auto;

    &:hover,
    &:focus,
    &:active {
        box-shadow: $box-shadow-hover;
        outline: none;
        opacity: 1;
    }

    &[disabled] {
        background-color: $disabled;
        border: 1px solid $disabled;
    }

    &--dark {
        @include button-style($dark);
    }

    &--success {
        @include button-style($success);
    }

    &--company {
        @include button-style(#ee8815);
    }

    &--danger {
        @include button-style($danger);
    }

    &__text {
        font-size: 1rem;
    }

    &__text+&__icon,
    &__icon+&__text {
        margin-left: 0.6em;
    }

    &__icon {
        position: relative;

        &--spinner {
            animation: spin 1s linear infinite;
        }
    }
}

.d_none {
    display: none;
}

.page-link {
    color: #ee8815;
    border-color: #ffe2c1;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        color: #ee8815;
    }
}

.error-feedback {
    font-size: .65rem;
    color: #f47272;
    margin: .5rem 0 0;
    display: inline-block;
}

fieldset {
    padding: 0;
    border: none;
}

.alert {
    padding: 0.75rem 1.25rem;
    background: #ee8838;
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: .3rem;
    box-shadow: 0px 5px 1rem lightgrey;
    color: white;

    hr {
        opacity: .3;
        border: none;
        height: 2px;
        background: rgb(255 255 255 / 75%);
    }

    p {
        color: rgb(255 255 255 / 75%);
        margin: .3rem 0;
    }
}

.close {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    position: relative;
    outline: none;
    border: none;
    float: right;
    background: none;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 22%;
        left: 47%;
        width: 1.5px;
        height: 50%;
        background: rgb(255 255 255 / 75%);
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.camera {
    position: fixed;
    top: -100%;

    &.nostrap {
        width: 23% !important;
        left: 15%;
        z-index: -3;

        @include breakpoint-up(md) {
            width: 13% !important;
        }
    }

    &.strap {
        width: 18% !important;
        right: 10%;
        transition: .2s all;
    }
}

.book,
.pencil,
.drone {
    position: absolute;


    @include breakpoint-up(md) {
        width: 200px;
    }
}

.book {
    left: 25%;
    width: 23%;
}

.pencil {
    width: 150px;
    right: 0;
    width: 12%;
}

.drone {
    left: 0;
    z-index: -1;
    width: 23%;
}

.last {
    height: 48vh;

    @include breakpoint-up(md) {
        height: 100vh;
    }
}

::placeholder {
    color: rgb(0 0 0 / 25%);
}
