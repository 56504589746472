// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Breakpoints
@import 'breakpoints';

// Animations
@import 'animations';

// Bootstrap
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/mixins/_border-radius';
@import '~bootstrap/scss/vendor/rfs';
@import '~bootstrap/scss/mixins/_lists';
@import '~bootstrap/scss/mixins/_pagination';
@import '~bootstrap/scss/_pagination';

// Globals
@import 'globals';
