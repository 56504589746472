@keyframes item {
    from {
        height: 0
    }

    to {
        height: 30%;
    }
}

@keyframes quarter {
    from {
        width: 0;
    }

    to {
        width: 50%;
    }
}

@keyframes scrollButton {
    from {
        opacity: 0;
        transform: translateY(15%) rotate(-90deg);
    }

    to {
        opacity: 1;
        transform: translateY(0) rotate(-90deg);
    }
}

@keyframes fromTop {
    from {
        opacity: 0;
        transform: translateX(-47.5%) translateY(-65%);
    }

    to {
        opacity: 1;
        transform: translateX(-47.5%) translateY(-50%);
    }
}

@keyframes fromBottom {
    from {
        opacity: 0;
        transform: translateY(15%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fromLeft {
    from {
        opacity: 0;
        transform: translateX(-15%) translateY(-50%);
    }

    to {
        opacity: 1;
        transform: translateX(0) translateY(-50%);
    }
}

@keyframes fromRight {
    from {
        opacity: 0;
        transform: translateX(15%) translateY(-50%);
    }

    to {
        opacity: 1;
        transform: translateX(0) translateY(-50%);
    }
}

@keyframes floating {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 1.2rem);
    }

    to {
        transform: translate(0, -0px);
    }
}

@keyframes floatingReverse {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, -1.2rem);
    }

    to {
        transform: translate(0, -0px);
    }
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(720deg);
    }
}
