// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$orange-1:#FBF4D8;
$orange-2: #FBE70E;
$orange-3: #F5C75C;
$orange-4: #F4AD04;
$orange-5 : #EE8815;

$light: #fffdf5;
$dark: rgb(40, 38, 42);

$success: #2ed13b;
$warning: #f87526;
$danger: #fd3b37;
$disabled: #aeb8c0;
$white: #ffffff;

$box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0px 2px 3px 2px rgba(0, 0, 0, 0.2);

$button-icon-padding: 1em;
$button-text-color: $white;

@mixin button-style($button-color) {
    border: 1px solid $button-color;
    background: $button-color;
}
